
import { Pond } from '@/data/pond/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    pond: {
      type: Object as PropType<Pond>,
      required: true,
    },
  },
  computed: {
    statusStyle(): string {
      return this.pond.inactive ? 'status--inactive' : 'status--active'
    },
    severity(): string {
      return this.pond.inactive ? 'warning' : 'success'
    },
  },
})
