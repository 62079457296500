
import { defineComponent, inject } from 'vue'
import { debounce } from 'debounce'

import { GetPondsParams, Pond } from '@/data/pond/types'
import { PondRepositoryKey } from '@/data/injectables'
import { DEBOUNCE_INTERVAL } from '@/config'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import PondListItem from '@/views/pond/PondListItem.vue'
import SearchNavBar from '@/components/navbar/SearchNavBar.vue'
import ActionBar from '@/components/ActionBar.vue'
import ActionButton from '@/components/ActionButton.vue'
import ListWrapper from '@/components/ListWrapper.vue'

export default defineComponent({
  components: {
    PondListItem,
    SearchNavBar,
    ActionBar,
    ActionButton,
    ListWrapper,
  },
  setup: () => {
    const pondRepository = inject(PondRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { pondRepository, handleError }
  },
  data() {
    return {
      error: false,
      loading: false,
      searchText: '',
      ponds: [] as Pond[],
    }
  },
  watch: {
    searchText() {
      this.loading = true
      this.loadPondsDebounced()
    },
  },
  created() {
    this.loadPonds()
  },
  methods: {
    handleEdit(pondId: string): void {
      this.$router.push({ path: `/ponds/${pondId}/edit` })
    },
    handleCreate(): void {
      this.$router.push({ path: '/ponds/create' })
    },
    async loadPonds(): Promise<void> {
      try {
        this.error = false
        this.loading = true
        const params: GetPondsParams = {}
        if (this.searchText) {
          params.name = this.searchText
        }
        const ponds = await this.pondRepository?.getPonds(params)
        this.ponds = ponds || []
      } catch (e) {
        this.error = true
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    loadPondsDebounced: debounce(function (this: { loadPonds: () => void }) {
      this.loadPonds()
    }, DEBOUNCE_INTERVAL),
  },
})
